import React from 'react';
import PropTypes from 'prop-types';

import ProcessCreditCard from './Process';
import CreditCardDetails from './Details';

import { toast } from 'react-toastify';

const CreditCardContext = React.createContext();

export default class CreditCards extends React.Component {
    static propTypes = {
        displayPayment: PropTypes.bool.isRequired,
        cardSelectable: PropTypes.bool.isRequired,
        fetchPaymentSourcesOnMount: PropTypes.bool.isRequired,
        setupCardElement: PropTypes.bool.isRequired,
        invoice: PropTypes.object,
        updateInvoice: PropTypes.func,
        chargeAmount: PropTypes.number,
        stripeKey: PropTypes.string.isRequired,
        makePaymentUrl: PropTypes.string,
        paymentSourcesUrl: PropTypes.string.isRequired,
        deleteSourceUrl: PropTypes.string.isRequired,
        updateCustomerUrl: PropTypes.string.isRequired,
        addPaymentSourceUrl: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.baseState = {
            validatingCard: false,
            submittingPayment: false,
            storeNewCard: null,
            newCardError: null,
            useNewCard: true,
            automaticPayments: null,
            paymentError: null,
            selectedCard: null,
            paymentSources: [],
            loading: false,
            defaultSource: null,
            removingSource: false,
            updatingSource: false,
            addingSource: false,
            completed: false,
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        if (this.props.fetchPaymentSourcesOnMount) {
            this.fetchPaymentSources()
        }

        if (this.props.setupCardElement) {
            this.setupCardElement();
        }
    }

    fetchPaymentSources = () => {
        this.setState({ loading: true });

        $.ajax({
            method: "GET",
            url: `${this.props.paymentSourcesUrl}.json`
        }).done(customer => {
            let paymentSources, useNewCard, defaultSource;
            if (!customer.id) {
                paymentSources = [];
                useNewCard = true;
                defaultSource = null;
            } else {
                paymentSources = customer.sources.data;
                useNewCard = paymentSources.length < 1;
                defaultSource = customer.default_source;
            }
            this.setState({ defaultSource, paymentSources, useNewCard, storeNewCard: useNewCard, automaticPayments: useNewCard, loading: false });
        });
    };

    setupCardElement = () => {
        const stripe = Stripe(this.props.stripeKey);
        const elements = stripe.elements();
        const card = elements.create("card");

        this.stripe = stripe;
        this.stripeCard = card;

        const cardReady = setInterval(() => {
            if ($("#card-element")[0]) {
                clearInterval(cardReady);

                card.mount("#card-element");
                card.on("change", (e) => {
                    if (e.complete) {
                        this.setState({ validatingCard: true }, () => {
                            this.stripe.createToken(this.stripeCard).then(result => {
                                if (result.error) {
                                    this.setState({ newCardError: result.error.message, validatingCard: false });
                                } else {
                                    this.setState({ selectedCard: result.token.id, validatingCard: false, newCardError: false });
                                }
                            });
                        });
                    }
                });
            }
        }, 1);
    };

    addSource = () => {
        this.setState({ addingSource: true });

        $.ajax({
            method: "POST",
            url: this.props.addPaymentSourceUrl,
            data: { token: this.state.selectedCard }
        }).done(customer => {
            this.setState({ defaultSource: customer.default_source, paymentSources: customer.sources.data, selectedCard: null });

            toast.success("Successfully added new Credit Card!", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(res => {
            const error = (res.responseJSON || {}).error;
            if (error) {
                this.setState({ paymentError: error });
            } else {
                toast.error("Something went wrong... Unable to add new Credit Card", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).always(() => {
            this.setState({ addingSource: false });
        })
    };

    updateDefaultSource = (id) => {
        this.setState({ updatingSource: true });

        $.ajax({
            method: "PUT",
            url: this.props.updateCustomerUrl,
            data: {
                customer: {
                    default_source: id
                }
            }
        }).done(customer => {
            this.setState({ defaultSource: customer.default_source, paymentSources: customer.sources.data });

            toast.success("Successfully updated default payment!", {
                position: toast.POSITION.TOP_CENTER
            });
        }).fail(() => {
            toast.error("Something went wrong... Unable to update default payment", {
                position: toast.POSITION.TOP_CENTER
            })
        }).always(() => {
            this.setState({ updatingSource: false });
        })
    };

    deletePaymentSource = (source) => {
        if (confirm(`Are you sure you would like to remove the stored card ending in ${source.last4}?`)) {
            this.setState({ removingSource: true });

            $.ajax({
                method: "DELETE",
                url: `${this.props.deleteSourceUrl}.json`,
                data: {
                    source: {
                        token: source.id
                    }
                }
            }).done(customer => {
                toast.success("Successfully removed stored card!", {
                    position: toast.POSITION.TOP_CENTER
                });

                const paymentSources = customer.sources.data;
                const useNewCard = paymentSources.length < 1;
                const defaultSource = customer.default_source;
                this.setState({ paymentSources, useNewCard, defaultSource });
            }).fail(() => {
                toast.error("Something went wrong... Unable to remove stored card", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).always(() => {
                this.setState({ removingSource: false })
            });
        }
    };

    makePayment = () => {
        this.setState({ submittingPayment: true, paymentError: false });

        let store_card;
        if (this.state.useNewCard && this.state.storeNewCard) {
            this.stripe.createToken(this.stripeCard).then(result => {
                if (!result.error) {
                    store_card = result.token.id;
                    this.paymentRequest(store_card);
                }
            });
        } else {
            this.paymentRequest();
        }
    };

    paymentRequest = (store_card) => {
        $.ajax({
            method: "POST",
            url: `${this.props.makePaymentUrl}.json`,
            data: {
                payment: {
                    token: this.state.selectedCard,
                    amount: this.props.chargeAmount,
                    automatic_payments: this.state.automaticPayments,
                    store_card
                }
            }
        }).done(invoice => {
            this.props.updateInvoice(invoice);
            this.setState({ completed: true, submittingPayment: false })
        }).fail(res => {
            const error = (res.responseJSON || {}).error;
            if (error) {
                this.setState({ paymentError: error, submittingPayment: false });
            } else {
                toast.error("Something went wrong... Unable to submit payment", {
                    position: toast.POSITION.TOP_CENTER
                });

                this.setState({ submittingPayment: false });
            }
        });
    };

    selectCard = (card) => {
        this.setState({ selectedCard: card.id, useNewCard: false, storeNewCard: false, automaticPayments: true })
    };

    toggleStoreNewCard = () => {
        this.setState({ storeNewCard: !this.state.storeNewCard, automaticPayments: !this.state.automaticPayments })
    };

    toggleUseNewCard = () => {
        this.setState({ useNewCard: !this.state.useNewCard, storeNewCard: true, automaticPayments: true, selectedCard: null })
    };

    toggleAutoPayment = () => {
        this.setState({ automaticPayments: !this.state.automaticPayments });
    };

    selectedCardIsId = () => {
        return (this.state.selectedCard && this.state.selectedCard.length > 0 && !this.state.selectedCard.startsWith("tok_"));
    };

    render() {
        return(
            <CreditCardContext.Provider
                value={{
                    ...this.state,
                    invoice: this.props.invoice,
                    cardSelectable: this.props.cardSelectable,
                    selectCard: this.selectCard,
                    toggleStoreNewCard: this.toggleStoreNewCard,
                    toggleUseNewCard: this.toggleUseNewCard,
                    makePayment: this.makePayment,
                    addSource: this.addSource,
                    selectedCardIsId: this.selectedCardIsId,
                    toggleAutoPayment: this.toggleAutoPayment,
                    updateDefaultSource: this.updateDefaultSource,
                    deletePaymentSource: this.deletePaymentSource,
                    chargeAmount: this.props.chargeAmount
                }}
            >
                <CreditCardDetails />
                <ProcessCreditCard type={this.props.displayPayment ? "payment" : "newCard"} />

            </CreditCardContext.Provider>
        )
    }
}

export const CreditCardConsumer = CreditCardContext.Consumer;
