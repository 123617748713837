import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from 'Utils';

import { CreditCardConsumer } from './Index';

export default class CardPayment extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired
    }

    validChargeAmount = (invoice, chargeAmount) => {
        if (!chargeAmount) return false;
        if (parseFloat(invoice.grand_total) >= chargeAmount) return true;

        return false;
    };

    render() {
        return(
            <CreditCardConsumer>
                {(context) => (
                    <Fragment>
                        { !context.completed &&
                            <div className="display-flex flex-column align-items-center">
                                { this.props.type === "payment" &&
                                    <Fragment>
                                        { context.paymentSources.length > 0 &&
                                            <h4 className="text-center">
                                                Use New Card:
                                                <input
                                                    type="checkbox"
                                                    className="margin-5-left"
                                                    checked={context.useNewCard}
                                                    onChange={context.toggleUseNewCard}
                                                />
                                            </h4>
                                        }
                                    </Fragment>
                                }

                                <div className={`col-md-8 col-sm-12 col-xs-12 ${(context.useNewCard || this.props.type === "newCard") ? "display-block" : "display-none"}`}>
                                    <label>Credit/Debit Card:</label>
                                    <div id="card-element"></div>
                                    { this.props.type === "payment" &&
                                        <div className="form-group display-flex justify-content-end margin-5-top no-margin-bottom">
                                            <label htmlFor="store-card" className="margin-5-right">Store New Card for future use:</label>
                                            <input
                                                id="store-card"
                                                type="checkbox"
                                                checked={context.storeNewCard || ""}
                                                onChange={context.toggleStoreNewCard}
                                            />
                                        </div>
                                    }
                                </div>

                                { context.validatingCard &&
                                    <div className="display-flex flex-column justify-content-center align-items-center">
                                        <h4>
                                            Validating card, please wait...
                                        </h4>
                                        <div>
                                            <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                        </div>
                                    </div>
                                }

                                { context.newCardError &&
                                    <div className="alert alert-warning no-margin-bottom margin-20-top">
                                        { context.newCardError }
                                    </div>
                                }

                                { (this.props.type === "payment" && context.invoice.type === "service_stops" && (context.storeNewCard || context.selectedCardIsId())) &&
                                    <Fragment>
                                        <div className="width-100">
                                            <hr/>
                                        </div>
                                        <div className="form-group display-flex flex-column align-items-center no-margin">
                                            <label className="margin-5-right no-margin-bottom text-center" htmlFor="auto-payments">
                                                Automatically charge selected card for Service Stops Invoices in the future:
                                            </label>
                                            <input
                                                id="auto-payments"
                                                type="checkbox"
                                                checked={context.automaticPayments}
                                                onChange={context.toggleAutoPayment}
                                            />
                                        </div>
                                        <div className="width-100">
                                            <hr/>
                                        </div>
                                    </Fragment>
                                }

                                <div className="display-flex align-items-center flex-column">
                                    { context.submittingPayment &&
                                        <div className="display-flex flex-column justify-content-center align-items-center margin-20-bottom">
                                            <h4>
                                                Submitting Payment...
                                            </h4>
                                            <div>
                                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw align-self-center flex-1"></i>
                                            </div>
                                        </div>
                                    }
                                    { context.paymentError &&
                                        <div className="alert alert-danger">
                                            { context.paymentError }
                                        </div>
                                    }
                                    <div className="process-button-wrapper">
                                        { this.props.type === "payment" &&
                                            <button
                                                className="btn btn-lg btn-success" disabled={!context.selectedCard || context.submittingPayment || context.newCardError || context.updatingSource || context.removingSource || !this.validChargeAmount(context.invoice, context.chargeAmount)}
                                                onClick={context.makePayment}
                                            >
                                                {  context.selectedCard &&
                                                    <Fragment>
                                                        { this.validChargeAmount(context.invoice, context.chargeAmount) ? `Make Payment for ${ Utils.floatToCurrency(context.chargeAmount) } with selected card now` : "Enter valid amount to Make Payment" }
                                                    </Fragment>
                                                }
                                                { !context.selectedCard && "Enter Card Details to Make Payment" }
                                            </button>
                                        }
                                        { this.props.type === "newCard" &&
                                            <button
                                                className="btn btn-lg btn-success"
                                                disabled={!context.selectedCard || context.newCardError || context.addingSource || context.removingSource}
                                                onClick={context.addSource}
                                            >
                                                { context.selectedCard && "Store New Card" }
                                                { !context.selectedCard && "Input Card Number to Store" }
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        { context.completed &&
                            <Fragment>
                                <div className="checkmark-animation margin-20-bottom">
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                </div>
                                <h3 className="text-center margin-20-bottom">
                                    Your Payment has been successfully made!
                                </h3>
                            </Fragment>
                        }
                    </Fragment>
                )}
            </CreditCardConsumer>
        )
    }
}
