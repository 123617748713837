import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify'

import Utils from 'Utils';

export default class PdfModal extends React.Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        id: PropTypes.number,
        internal: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.baseState = {
            completed: false
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        $(this.modalEl).on("show.bs.modal", (e) => {
            $(e.target).addClass("center-screen-modal");
        });

        $(this.modalEl).on("hide.bs.modal", (e) => {
            $(e.target).removeClass("center-screen-modal");

            this.setState({ ...this.baseState });
        });

        if (this.props.id) {
            $(`a[data-invoice-pdf=${this.props.id}]`).on("click", (e) => {
                e.preventDefault();
                this.makePdf();
            });
        }
    }

    makePdf = () => {
        $(this.modalEl).modal("show");

        const downloadKey = moment().unix();

        const url = this.props.internal ? `${this.props.url}&download_key=${downloadKey}` : `${this.props.url}.pdf?download_key=${downloadKey}`;

        $("<a>").attr("href", url)[0].click();
        const cookieVal = Utils.getCookieValue(`breezy_pdf_downloaded_${downloadKey}`);
        const downloadInterval = setInterval(() => {
            if (cookieVal !== Utils.getCookieValue(`breezy_pdf_downloaded_${downloadKey}`)) {
                this.setState({ completed: true });
            }
        }, 1000);
    };

    render() {
        return(
            <div
                ref={(e) => this.modalEl = e}
                id="client-pdf-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">
                                Generating Invoice PDF
                            </h4>
                        </div>
                        <div className="modal-body">
                            { !this.state.completed &&
                                <div className="display-flex flex-column justify-content-center align-items-center">
                                    <h3>
                                        Generating PDF
                                    </h3>
                                    <h4>
                                        (this may take a few minutes)
                                    </h4>
                                    <div>
                                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                    </div>
                                </div>
                            }
                            { this.state.completed &&
                                <Fragment>
                                    <div className="checkmark-animation margin-20-bottom">
                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                    </div>
                                    <h3 className="text-center margin-20-bottom">
                                        Your PDF has been generated successfully
                                    </h3>
                                </Fragment>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
